<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        v-if="isMaster"
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">닫기</v-btn>
      <v-btn
        v-if="!isMaster"
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">공유</v-btn>
      <v-btn
        v-if="!isMaster"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="disagree">해제</v-btn>
      <v-btn
        v-if="!isMaster"
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>
      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container v-if="!isMaster">
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12">
                <v-text-field
                  :value="staffInfo.email"
                  filled
                  shaped
                  readonly
                  prepend-icon="mdi-email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  :value="staffInfo.name"
                  filled
                  shaped
                  readonly
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-select
                  ref="selAuth1"
                  v-model="staffInfo.auth1"
                  :items="select1"
                  item-text="text"
                  item-value="val"
                  label="계약,수금 권한"
                  @change="chkAuth"
                  menu-props="auto"
                  prepend-icon="mdi-credit-card-check-outline"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12">
                <v-select
                  ref="selAuth2"
                  v-model="staffInfo.auth2"
                  :items="select2"
                  item-text="text"
                  item-value="val"
                  label="비용 권한"
                  @change="chkAuth"
                  menu-props="auto"
                  :filled="staffInfo.auth1 === 1"
                  :shaped="staffInfo.auth1 === 1"
                  :readonly="staffInfo.auth1 === 1"
                  :append-icon="staffInfo.auth1 === 1 ? 'mdi-lock' : 'mdi-menu-down'"
                  :background-color="staffInfo.auth1 === 1 ? 'grey lighten-3' : ''"
                  prepend-icon="mdi-cash-multiple"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12">
                <v-select
                  ref="selAuth3"
                  v-model="staffInfo.auth3"
                  :items="select3"
                  item-text="text"
                  item-value="val"
                  label="타임시트 권한"
                  @change="chkAuth"
                  menu-props="auto"
                  :filled="staffInfo.auth1 === 1"
                  :shaped="staffInfo.auth1 === 1"
                  :readonly="staffInfo.auth1 === 1"
                  :append-icon="staffInfo.auth1 === 1 ? 'mdi-lock' : 'mdi-menu-down'"
                  :background-color="staffInfo.auth1 === 1 ? 'grey lighten-3' : ''"
                  prepend-icon="mdi-timer-sand"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" style="height: 80px">
              <transition name="fade">
                <v-alert type="info" dense text class="text-body-2">
                  공유자의 업무부서 배치 등은 [업무부서관리]에서, 상세 인사관리는 [인사정보]에서 가능합니다.
                </v-alert>
              </transition>
            </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-container v-else grid-list-xs>
          <v-row
              align="center"
              justify="center"
              no-gutters
            >
            <v-col cols="12" xs="12">
              <v-text-field
                :value="staffInfo.email"
                filled
                shaped
                readonly
                prepend-icon="mdi-email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                :value="staffInfo.name"
                filled
                shaped
                readonly
                prepend-icon="mdi-account"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                :value="sel1[staffInfo.auth1]"
                filled
                shaped
                readonly
                prepend-icon="mdi-credit-card-check-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                :value="sel2[staffInfo.auth2]"
                filled
                shaped
                readonly
                prepend-icon="mdi-cash-multiple"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                :value="sel3[staffInfo.auth3]"
                filled
                shaped
                readonly
                prepend-icon="mdi-timer-sand"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" style="height: 80px">
              <transition name="fade">
                <v-alert type="info" dense text class="text-body-2">
                  공유자의 업무부서 배치 등은 [업무부서관리]에서, 상세 인사관리는 [인사정보]에서 가능합니다.
                </v-alert>
              </transition>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-divider></v-divider> -->
      </v-card-text>
      <!-- <v-card-actions
        slot="footer"
        class="pa-3 mt-0"
      >
        <v-btn text icon small color="primary" disabled>
          <v-icon>print</v-icon>
        </v-btn>
        <v-btn text icon small color="primary" disabled>
          <v-icon>get_app</v-icon>
        </v-btn>
        <v-btn text icon small color="primary" disabled>
          <v-icon>phone_iphone</v-icon>
        </v-btn>
        <v-btn text icon small color="primary" disabled>
          <v-icon>local_post_office</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="text-caption mr-2">{{ staffInfo.wname }}</span><span class="capttext-captionion">{{ staffInfo.updatedAt }}</span>
      </v-card-actions> -->

    </baseModal>

    <!-- !! overlay -->
    <v-overlay :value="overlay" opacity="0.25">
      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
    </v-overlay>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  props: [ 'staffInfo', 'isMaster' ],

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 620,
      zIndex: 200
    },
    // 구분: for Form
    form: {
      email: '',
      name: '',
      auth1: 0,
      auth2: 0,
      auth3: 0
    },
    select1: [
      { text: '접근불허', val: 0 },
      { text: '접근허용(계약,수금)', val: 1 }
    ],
    select2: [
      { text: '접근불허', val: 0 },
      { text: '접근허용(비용)', val: 1 }
    ],
    select3: [
      { text: '접근불허', val: 0 },
      { text: '접근허용(타임시트)', val: 1 }
    ],
    sel1: ['접근불허', '접근허용(계약,수금)'],
    sel2: ['접근불허', '접근허용(비용)'],
    sel3: ['접근불허', '접근허용(타임시트)'],
    // 구분: 오버레이용 변수
    overlay: false
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    // 참고: 부모에서 선언한 순간 mount되기 때문에 팝업이 열린 후엔 mounted() 는 써먹지 못함
    // console.log(this.staffInfo)

    // console.log(this.select4[this.staffInfo.auth1])
  },

  methods: {
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    initDialog () {
      // form 초기화
      // this.$refs.form.reset()
    },
    open (title, options) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log('여기는 한발 늦다')
        // console.log(this.staffInfo)

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 등록 버튼
    async agree () {
      try {
        this.overlay = true

        this.form.email = this.staffInfo.email
        this.form.name = this.staffInfo.name
        this.form.auth1 = this.staffInfo.auth1
        this.form.auth2 = this.staffInfo.auth2
        this.form.auth3 = this.staffInfo.auth3
        // console.log(this.form)
        if (!this.form.email) throw new Error('정상적인 데이터가 아닙니다.')

        const { data } = await this.$axios.post('admin/staff/editStaff', this.form)
        if (!data.success) throw new Error('수정할 수 없습니다. 다시 한 번 시도하시기 바랍니다')

        setTimeout(() => {
          this.overlay = false
          this.resolve(true)
          this.dialog = false
        }, 350)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 해제 -- 관련 항목 삭제 때문에 시간이 걸릴 수 있다
    async disagree () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `해당 계정의 공유해제 이후에는<br>이 조직의 정보에 접근할 수 없습니다.<br>그러나, 해당자의 등록 정보는 그대로 유지됩니다.`
        if (await pop.open('공유해제', msg, { color: 'error', width: 400 })) {
          this.overlay = true

          // 백앤드에 공유해제에 필요한 변수를 보낸다.
          this.form.email = this.staffInfo.email
          this.form.name = this.staffInfo.name
          const { data } = await this.$axios.post('admin/staff/releaseShare', this.form)
          if (!data.success) throw new Error('공유해제 할 수 없습니다. 다시 한 번 시도하시기 바랍니다')

          setTimeout(() => {
            this.overlay = false
            this.resolve(true)
            this.dialog = false
          }, 350)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 중요: 권한셀렉트1의 값이 1이면 다른 셀렉트의 값도 강제로 1로 만들어야 함
    // thif.rom.auth2 = 1 만 있으면 값은 변경되지만, 뷰 렌더링이 안됨(눈에 보이는 변화가 없음)
    // this.$refs.selAuth2.setValue(1) 코드가 있어야 렌더링이 됨
    chkAuth () {
      if (this.staffInfo.auth1 === 1) {
        this.staffInfo.auth2 = 1
        this.staffInfo.auth3 = 1
        this.$refs.selAuth2.setValue(1)
        this.$refs.selAuth3.setValue(1)
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
