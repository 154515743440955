<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">공유</v-btn>
      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-form ref="form">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:350px">
                  <v-text-field
                    v-model.trim="form.email"
                    required
                    v-validate="'required|email|cantHangul|cantEmailSpc|max:50'"
                    :error-messages="errors.collect('email')"
                    data-vv-name="email"
                    placeholder="address@gmail.com"
                    maxlength="50"
                    counter="50"
                    name="email"
                    type="text"
                    label="공유자의 구글계정"
                    @keyup="chkMultiReset"
                  >
                    <template v-slot:prepend>
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small class="mt-1">mdi-help-circle-outline</v-icon>
                        </template>
                        공유자의 유효한 구글계정이 필요합니다.
                      </v-tooltip>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-icon>mdi-email</v-icon>
                    </template>
                  </v-text-field>
                </div>
                <v-btn small text class="ma-2"
                  :color="mustChk ? 'error' : 'indigo'"
                  @click="chkMultiDo"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.name"
                required
                v-validate="'required|cantSpc|max:30'"
                :error-messages="errors.collect('nameValid')"
                data-vv-name="nameValid"
                maxlength="30"
                counter="30"
                type="text"
                label="이름"
                prepend-icon="mdi-account"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-select
                ref="selAuth1"
                v-model="form.auth1"
                :items="select1"
                item-text="text"
                item-value="val"
                label="계약,수금 권한"
                @change="chkAuth"
                menu-props="auto"
                prepend-icon="mdi-credit-card-check-outline"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12">
              <v-select
                ref="selAuth2"
                v-model="form.auth2"
                :items="select2"
                item-text="text"
                item-value="val"
                label="비용 권한"
                @change="chkAuth"
                menu-props="auto"
                :readonly="form.auth1 === 1"
                :background-color="form.auth1 === 1 ? 'grey lighten-2' : ''"
                prepend-icon="mdi-cash-multiple"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12">
              <v-select
                ref="selAuth3"
                v-model="form.auth3"
                :items="select3"
                item-text="text"
                item-value="val"
                label="타임시트 권한"
                @change="chkAuth"
                menu-props="auto"
                :readonly="form.auth1 === 1"
                :background-color="form.auth1 === 1 ? 'grey lighten-2' : ''"
                prepend-icon="mdi-timer-sand"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" style="height: 80px">
              <transition name="fade">
                <v-alert v-if="isExistUse" v-show="chkMulti" type="info" dense text class="text-caption">
                  공유자의 업무부서 배치 등은 [업무부서관리]에서, 상세 인사관리는 [인사정보]에서 가능합니다.
                </v-alert>
                <v-alert v-else v-show="chkMulti" type="error" dense text class="text-caption">
                  가입 되지 않은 회원이므로, 사전등록합니다. 차후 가입시 상기 내용대로 공유설정이 자동으로 이루어집니다.
                </v-alert>
              </transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

    </baseModal>
  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

// filters
import isHan from '@/filters/isHangul'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 620,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      email: '',
      name: '',
      auth1: 0,
      auth2: 0,
      auth3: 0,
      rejoin: false
    },
    select1: [
      { text: '접근불허', val: 0 },
      { text: '접근허용(계약,수금)', val: 1 }
    ],
    select2: [
      { text: '접근불허', val: 0 },
      { text: '접근허용(비용)', val: 1 }
    ],
    select3: [
      { text: '접근불허', val: 0 },
      { text: '접근허용(타임시트)', val: 1 }
    ],
    dictionary: {
      messages: ko.messages,
      attributes: {
        masterEmail: '공유자 이메일',
        nameValid: '이름'
      }
    },
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 중복확인
    sharedName: '', // 참고: 공유자 이름
    isExistUse: false // 참고: 이미 회원인지 여부
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  created () {
    // 중요: vee-validate 커스텀 룰 적용 - 한글은 허용안됨
    this.$validator.extend('cantHangul', {
      getMessage: field => `한글은 허용되지 않습니다`,
      validate: value => {
        return !isHan(value) // false 리턴이면 오류로 처리됨
      }
    })

    // 중요: vee-validate 커스텀 룰 적용 - 특수문자 허용안됨
    // !! 특수문자를 거르는 필터. 공백허용(이름 중간에 공백이 들어갈 수 있으므로)
    this.$validator.extend('cantSpc', {
      getMessage: field => '특수문자는 허용되지 않습니다',
      validate: value => {
        const pattern = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/gi
        return !pattern.test(value)
      }
    })

    // 중요: vee-validate 커스텀 룰 적용 - 특수문자 허용안됨
    // !! 이메일용이라 @-_. 만 허용된다.
    this.$validator.extend('cantEmailSpc', {
      getMessage: field => 'email항목의 값은 유효한 이메일 형식이어야 합니다',
      validate: value => {
        const pattern = /[{}[\]/?,;:|)*~`!^+<>#$%&\\=('"]/gi
        return !pattern.test(value)
      }
    })
  },

  watch: {
    'form.email': { // !! 이메일 자동감지 - 한글만 필터링
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          // 중복 체크 관련 초기화
          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
        }
      }
    }
  },

  methods: {
    isHan, // 한글 필터
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    async initDialog () {
      // form validation 초기화
      this.$validator.reset()

      this.form.email = ''
      this.form.name = ''
      this.form.auth1 = 0
      this.form.auth2 = 0
      this.form.auth3 = 0

      this.mustChk = false // 계약번호 중복체크를 해야 하는지 여부
      this.chkMulti = false // 중복체크 초기화
      this.isExistUse = false
      this.sharedName = ''
    },
    async open (title, options) {
      this.dialog = true
      this.title = title
      this.options = Object.assign(this.options, options)

      await this.initDialog()

      // 프로미스를 리턴하면서 resolve를 매칭하고있다.
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    // 구분: 공유등록 처리
    // 1) 회원가입된 유저가 공유요청한 것에 대한 공유처리 - manage.users에 유저가 등록 되어있으니 이름을 가져와서 매칭한다
    // 2) 사전등록 - manage.users 에 없는 이메일이어야 한다!
    async agree () {
      try {
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')
        if (this.mustChk && !this.chkMulti) throw new Error('중복체크를 하셔야 합니다.')
        // console.log(this.form)

        if (this.isExistUse) { // @: 이미 회원가입되었고 공유요청중임
          const { data } = await this.$axios.post('admin/staff/addStaff', this.form)
          if (!data.success) throw new Error(`등록할 수 없습니다.다시 한 번 시도하시기 바랍니다: ${data.message}`)
        } else { // @: 사전등록인 경우
          const { data } = await this.$axios.post('admin/staff/preRegist', this.form)
          if (!data.success) throw new Error(`사전등록에 실패 하였습니다.다시 한 번 시도하시기 바랍니다: ${data.message}`)
        }

        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    async cancel () {
      try {
        // 다이얼로그를 닫으면서 초기화 해야 한다!
        await this.initDialog()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: email 중복체크
    async chkMultiDo () {
      try {
        // 입력값 확인
        let emailValidate = await this.$validator.validate('email')
        if (!emailValidate) throw new Error('유효한 메일주소를 입력해 주십시오.')

        if (this.form.email.length > 0) {
          // console.log(this.form)
          this.mustChk = false
          this.chkMulti = true

          const { data } = await this.$axios.post('auth/getUserInfo', this.form)
          if (!data.success) throw new Error(`유저정보를 패칭할 수 없습니다: ${data.message}`)

          if (data.userInfo) { // @:1 회원임 - 이미 회원인 경우 팀에 등록(즉, 공유요청한 회원)
            const { userInfo: ui } = data
            if (ui.email && !ui.dbcode && ui.isUse === 'R') { // @: 공유 요청 중이다!
              //
              // 중요: 이전에 회원이었다가 공유해제 후 다시 공유하려는 유저도 여기로 빠질 수 있다.
              // 주의: 일반유저의 공유요청인 경우에 해당된다!
              // !! 해당 유저DB.staffs 에 이메일이 존재하는지 확인 - 기존에 본 사무실에 공유했던 회원인지 확인한다.
              const { data } = await this.$axios.get(`admin/staff/oneProfile/${ui.email}`)
              if (!data.success) throw new Error(`유저정보를 패칭할 수 없습니다: ${data.message}`)
              if (data.staffInfo) {
                // !! 1) 본 사무살에 공유했었던 회원이다!
                this.isExistUse = true
                this.form.name = ui.name
                this.sharedName = ui.name
                this.form.rejoin = true // 중요: 재가입 여부 => true
              } else {
                // !! 2) 본 사무실에 공유했었던 회원이 아니다.
                this.isExistUse = true
                this.form.name = ui.name
                this.sharedName = ui.name
                this.form.rejoin = false // 중요: 재가입 여부 => false
              }
              //
            } else if (ui.email && ui.dbcode && (ui.isUse === 'Y' || ui.isUse === 'F')) { // @: 이미 공유된 유저다
              //
              await this.initDialog()
              throw new Error('이미 공유처리된 유저입니다.')
              //
            } else if (ui.email && !ui.dbcode && ui.isAgree === 'N' && ui.isUse === 'W') { // @: 탈퇴한 유저다
              //
              await this.initDialog()
              throw new Error('탈퇴처리된 유저입니다.')
              //
            } else if (ui.email && !ui.dbcode && ui.isUse === 'N') { // @: 공유해제(퇴직)한 유저다.
              //
              // 중요: 이전에 회원이었다가 공유해제 후 다시 공유하려는 유저
              // 주의: 관리자가 사전등록하는 경우에 적용됨
              // !! 해당 유저DB.staffs 에 이메일이 존재하는지 확인 - 기존에 본 사무실에 공유했던 회원인지 확인한다.
              const { data } = await this.$axios.get(`admin/staff/oneProfile/${ui.email}`)
              if (!data.success) throw new Error(`유저정보를 패칭할 수 없습니다: ${data.message}`)
              if (data.staffInfo) {
                // !! 1) 본 사무살에 공유했었던 회원이다!
                this.isExistUse = true
                this.form.name = ui.name
                this.sharedName = ui.name
                this.form.rejoin = true // 중요: 재가입 여부 => true
              } else {
                // !! 2) 본 사무실에 공유했었던 회원이 아니다.
                // 즉, 기존에 다른 사무실에 있었으나 현재는 다른 사무실에 공유하려는 과정임
                this.isExistUse = true
                this.form.name = ui.name
                this.sharedName = ui.name
                this.form.rejoin = false // 중요: 재가입 여부 => false
              }
            } else {
              // 이건 에러임
              throw new Error('알수 없는 에러입니다. 확인하시고 다시 시도해 주세요.')
            }
          } else {
            // @:2 아직 회원이 아님 - 사전등록 루틴을 탄다!
            this.isExistUse = false
            this.sharedName = ''

            // 중요: 사전등록 회원이라도 기존회원일수 있다.
            // manage.users 에서는 삭제했지만 userDB.staffs 에는 남아있는 경우가 있다.
            // 다만, 여기서는 manage.users에는 없기 때문에 본사무실에 다시 들어가는지만 체크하면 된다.

            // !! 해당 유저DB.staffs 에 이메일이 존재하는지 확인 - 기존에 본 사무실에 공유했던 회원인지 확인한다.
            const { data } = await this.$axios.get(`admin/staff/oneProfile/${this.form.email}`)
            if (!data.success) throw new Error(`유저정보를 패칭할 수 없습니다: ${data.message}`)
            if (data.staffInfo) {
              // !! 1) 본 사무살에 공유했었던 회원이다!
              this.form.name = data.staffInfo.name
              this.sharedName = data.staffInfo.name
              this.form.rejoin = true // 중요: 재가입 여부 => true
            } else {
              // !! 2) 본 사무실에 공유했었던 회원이 아니다.
              this.form.name = ''
              this.sharedName = ''
              this.form.rejoin = false // 중요: 재가입 여부 => false
            }
          }
        } else {
          this.$validator.validate('email')
          throw new Error('이메일을 먼저 입력하세요')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 중복체크 리셋
    async chkMultiReset () {
      this.mustChk = true
      this.chkMulti = false
      this.form.name = ''
      this.isExistUse = false
      this.sharedName = ''

      // !![2021.7.26] 이메일의 마지막에 @를 입력하면 gmail.com 을 넣어준다.
      const emLen = this.form.email.length
      if (emLen > 0 && this.form.email.endsWith('@') && (this.form.email.indexOf('@') === emLen - 1)) {
        this.form.email = this.form.email + 'gmail.com'
      }
    },
    // 중요: 권한셀렉트1의 값이 1이면 다른 셀렉트의 값도 강제로 1로 만들어야 함
    // this.form.auth2 = 1 만 있으면 값은 변경되지만, 뷰 렌더링이 안됨(눈에 보이는 변화가 없음)
    // this.$refs.selAuth2.setValue(1) 코드가 있어야 렌더링이 됨
    chkAuth () {
      if (this.form.auth1 === 1) {
        this.form.auth2 = 1
        this.form.auth3 = 1

        this.$refs.selAuth2.setValue(1)
        this.$refs.selAuth3.setValue(1)
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
