<template>
  <v-container fluid :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-0'">

    <!-- <v-toolbar dense flat>
      <div class="text-caption text-lg-left blue-grey--text">
        공유자의 업무부서 배치 등은 [업무부서관리]에서,  상세 인사관리는 [인사정보]에서 가능합니다.
      </div>
      <v-spacer></v-spacer>
    </v-toolbar> -->
    <v-row
      align="center"
      justify="center"
      no-gutters
      class="ma-0 px-5 pt-3"
    >
      <v-col cols="12" class="hidden-xs-only">
        <v-alert
          text
          dense
          outlined
          color="info"
          icon="info"
        >
          <div class="text-body-2">
            공유를 위해서는 대상자의 구글계정이 필요합니다.
          </div>
          <div class="text-body-2">
            공유자의 업무부서 배치 등은 [업무부서관리]에서,  상세 인사관리는 [인사관리>인사정보]에서 가능합니다.
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <!-- <v-divider></v-divider> -->

    <v-toolbar dense flat color="grey lighten-2">
      <v-btn
        outlined
        color="primary"
        @click="sharePop1">
        <v-icon small class="mr-1">mdi-account-plus</v-icon>
        개별공유
      </v-btn>
      <!-- |
      <v-btn text color="primary">
        일괄공유
      </v-btn> -->
      <v-spacer></v-spacer>
      <!-- <v-btn text small>
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
      <v-btn text small>
        <v-icon small>mdi-magnify</v-icon>
        검색
      </v-btn> -->
      <div class="text-left hidden-xs-only" style="width: 60px">
        <v-tooltip left color="info">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              small
              color="grey lighten-4"
            >
              {{ staffs.length }} / {{ maxUserCnt }}
            </v-chip>
          </template>
          <span>총 {{ maxUserCnt }}명 중 {{ staffs.length }}명 사용중</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-card
      tile
      :elevation="0"
    >
      <v-data-table
        :headers="headers"
        :items="staffs"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-header
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="검색결과가 없습니다"
        loading-text="로딩중..."
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-list class="pa-0 ma-0">
            <v-list-item class="float-left">
              <v-list-item-content>
                <v-list-item-title class="text-body-2 ma-1">
                  <!-- <a @click="shareStaffPopup(item.email)">
                    <span class="text-body-2 font-weight-bold primary--text mr-2">{{ item.name }}</span>
                  </a> -->
                  <span
                    class="primary--text"
                    style="cursor:pointer;font-size: 0.975rem;font-weight: 550;"
                    @click="shareStaffPopup(item.email)"
                  >
                    <v-icon
                      class="mr-1 mb-1"
                      :color="levelColors[item.lv]"
                      style="font-size: 1.25rem;font-weight: 550;"
                    >
                      {{ levelIcons[item.lv] }}
                    </v-icon>
                    {{ item.name }}
                  </span>
                  <span class="text-body-2 mx-1">{{ item.email }}</span>
                  <span class="text-body-2 mx-1">
                    {{ (item.rank && item.position) ? `${item.rank}|${item.position}` : item.rank || item.position }}
                  </span>
                  <v-chip
                    small
                    class="mx-1"
                    color="grey lighten-4"
                    :text-color="item.auth1 ? 'teal' : 'error lighten-1'"
                    close
                    :close-icon="item.auth1 ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle'"
                  >
                    계약+수금
                  </v-chip>
                  <v-chip
                    small
                    class="mx-1"
                    color="grey lighten-4"
                    :text-color="item.auth2 ? 'teal' : 'error lighten-1'"
                    close
                    :close-icon="item.auth2 ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle'"
                  >
                    비용
                  </v-chip>
                  <v-chip
                    small
                    class="mx-1"
                    color="grey lighten-4"
                    :text-color="item.auth3 ? 'teal' : 'error lighten-1'"
                    close
                    :close-icon="item.auth3 ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle'"
                  >
                    타임시트
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle class="ma-1">
                  <span
                    class="text-body-2 mr-2"
                    :class="`${levelColors[item.lv]}--text`"
                  >
                    [{{ levelTitles[item.lv] }}]
                  </span>
                  <span v-html="item.view1"></span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <!-- 외부 페이지네이션 -->
      <div class="text-center py-5">
        <v-pagination
          v-model="options.page"
          :length="pages"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-card>

    <share-dialog ref="shareDialog"></share-dialog><!-- 개별공유 등록 팝업 -->
    <viewStaff-dialog :staffInfo="staffInfo" :isMaster="isMaster" ref="viewStaffDialog"></viewStaff-dialog><!-- 공유자 수정/해제 팝업 -->

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'

import shareDialog from '@/components/admin/ShareDialog' // 개별공유 등록 팝업
import viewStaffDialog from '@/components/admin/ViewStaffDialog' // 공유자 수정/해제 팝업

export default {
  components: {
    shareDialog,
    viewStaffDialog
  },

  data: () => ({
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'left',
        sortable: false
      }
    ],
    // 구분: 게시판용 변수들
    staffs: [],
    totalItems: 0,
    itemsPerPageOptions: [ 15, 30, 50, 100 ],
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {},
      // sort: [ 'name' ], // 정렬의 갯수만큼 초기값 지정해야 함
      // order: [ 'ASC' ], // [2022.6.13]가나다순정렬
      sort: [ 'id' ], // 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: [ 'ASC' ], // 주의:[2021.9.23]관리자부터 나오도록 오름차순으로 정렬
      offset: 0,
      limit: 1
    },
    // 구분: 초기값 설정이 필요한 변수들
    options: { // v-data-table 에 의해 자동으로 기본값이 들어있는 객체.
      itemsPerPage: 15, // 초기값을 지정했다. 기본값은 15라 매뉴얼에 나옴
      page: 1
    },
    loading: false,
    // 구분: 기타 변수들
    timeout: null,
    // @: 조직원 정보 다이얼로그
    staffInfo: '',
    // 구분: 계약된 to - manage.laworks 테이블의 max_user 필드에 있는 값
    maxUserCnt: 0,
    isMaster: false,
    // 구분: 유저레벨 색상
    levelTitles: {
      M: '관리자',
      G: '관리대행자',
      U: '공유유저'
    },
    // 구분: 유저레벨 색상
    levelIcons: {
      M: 'mdi-account-cog', // 관리자
      G: 'mdi-account-cog-outline', // 관리대행자
      U: 'mdi-account' // 일반유저
    },
    // 구분: 유저레벨 색상
    levelColors: {
      M: 'error', // 관리자
      G: 'success', // 관리대행자
      U: 'primary' // 일반유저
    }
  }),

  watch: {
    options: {
      handler () {
        this.list()
      },
      deep: true
    }
  },

  computed: {
    setOffset () {
      if (!this.options.page) return 0
      return (this.options.page - 1) * this.options.itemsPerPage
    },
    setLimit () {
      return this.options.itemsPerPage
    },
    pages () { // 페이지네이션 객체에 쓰인다
      if (this.options.itemsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.options.itemsPerPage)
    }
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        this.params.draw++
        this.params.offset = this.setOffset
        this.params.limit = this.setLimit

        // !! 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get('admin/staff/list', { params: this.params })
        if (!data.success) throw new Error(`list error: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        this.totalItems = data.totalItems

        // 참고: 데이터 리스트 순회하며 보여줄 데이터 생성
        await data.list.map(d => {
          d.view1 = ''
          if (d.teams && d.teams.length > 0) {
            const teamView = []
            d.teams.forEach(team => {
              if (team.name !== '회의실관리팀') { // !![2021.7.27] 회의실관리팀 제거(차후 복구)
                const colors = team.color.split(' ')
                const color2 = colors.length === 2 ? ` text--${colors[1]}` : ''
                const txtColor = `${colors[0]}--text${color2}`
                teamView.push(`<span class="text-body-2 ${txtColor}">${team.name}</span>`)
              }
            })
            d.view1 += teamView.join(' | ')
          }
        })

        // 참고: 데이터 반영
        this.staffs = data.list

        // !![2021.7.26] 계약된 유저 수(to)
        this.maxUserCnt = data.maxUserCnt

        this.loading = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 개별공유 등록 팝업
    async sharePop1 () {
      try {
        if (await this.$refs.shareDialog.open('공유관리')) {
          this.list() // 정상이면 리스프 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 참고: 조직원 공유 팝업 이벤트 핸들러 -> 등록한 정보를 열람하는 팝업을 열때
    async shareStaffPopup (email) {
      try {
        const { data } = await this.$axios.get(`admin/staff/profile/${email}`)
        if (!data.success) throw new Error('유저정보를 가져올 수 없습니다.')

        // 참고: 자식에게 보내는 데이터를 세팅
        this.isMaster = data.staffInfo.lv === 'M' // 중요: 이넘을 보내야 자식 팝업 디자인이 바뀐다!
        this.staffInfo = data.staffInfo

        // @: 최종 수정일 변환
        this.staffInfo.updatedAt = this.$moment(data.staffInfo.updatedAt).format('YYYY.MM.DD HH:mm')

        if (await this.$refs.viewStaffDialog.open('공유관리')) {
          this.list() // 정상이면 리스프 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
